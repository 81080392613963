.scrollable {
  overflow: auto;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pointer {
  cursor: pointer;
}

/* Mapbox */
.map {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 1;
}

.placeSearch {
  position: absolute;
  top: 30px;
  left: 380px;
  background-color: #fff;
  min-width: 480px;
  z-index: 10;
}

.placeDetailsCard {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 360px;
  max-height: calc(100vh - 130px);
  z-index: 10;
}

.placeTripCard {
  position: absolute;
  top: 30px;
  left: 30px;
  bottom: 30px;
  width: 330px;
  z-index: 10;
}

.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.saveDefault {
  position: absolute;
  bottom: 30px;
  right: 55px;
}

.slider-btn {
  position: absolute;
  top: calc(50% - 20px);
  background: none;
  border: none;
  color: #fff;
  left: 5px;
}

.slider-btn.right {
  left: auto;
  right: 5px;
}

.slider-btn:disabled {
  opacity: 0.3;
}

.sortable-group {
  min-height: 60px;
}

.color-circle {
  display: inline-block;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.transparentNavbar {
  position: absolute;
  width:100%;
  background-color: transparent !important;
}
.homeContainer {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
}

.leftContent {
    max-width: 540px;
}

.leftBg {
    width: 30%;
    background-color: #B3D0E6;
}

.title {
    font-size: 56px;
    font-family: kazimir, sans-serif;
    font-style: normal;
    color: #707070;
}

.rightLogo {
    position: absolute;
    top: 100px;
    right: 20px;
    height: 100vh;
    width: 80%;
    background-image: url(/logos/logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
}